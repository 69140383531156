import { IAccount } from '../models/Account'
import { toast } from 'react-toastify'
import PortalEnvironment from '../PortalEnvironment'

const ShareLinks = {

  account: (profile: IAccount) => {
    async function _copyToClipboard(link: string) {
      await navigator.clipboard.writeText(link)
      toast.success('Link copied to clipboard!')
    }

    const deepLink = `native?artist=${profile.username}`
    const httpLink = `${PortalEnvironment.hostname()}/${deepLink}`
    const nativeLink = `shadeapp:///${deepLink}`

    return {
      httpLink,
      nativeLink,
      copy: () => _copyToClipboard(httpLink)
    }
  },

  appstore: {
    ios: 'https://apps.apple.com/ca/app/shade/id6474099834',
    android: 'https://play.google.com/store/apps/details?id=com.shade.mobile'
  }

}

export default ShareLinks